<template>
  <div>
    <PageHeader
      title="FAQ > Catégories"
      description="Gestion des catégories de la FAQ"
      :button="{
        icon: 'fas fa-plus-circle',
        text: `Créer un catégorie`,
        action: create
      }"
    />
    <QuestionsSubmenu />

    <PageContent>
      <section class="list">
        <b-table
          :data="categories"
          v-if="domain"
          hoverable
          striped
          paginated
          @click="goToCategory"
        >

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="fas fa-frown"
                    size="is-large">
                  </b-icon>
                </p>
                <p>Aucune catégorie.</p>
              </div>
            </section>
          </template>

          <b-table-column field="label" label="Catégorie" v-slot="props">
            {{ props.row.label }}
          </b-table-column>
        </b-table>
      </section>
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import CreateCategory from './Modals/Create'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import QuestionsSubmenu from '../QuestionsSubmenu.vue'

export default {
  name: 'app-domains-list',
  components: { PageHeader, PageContent, QuestionsSubmenu },
  computed: {
    domain () {
      this.getCategories()
      return this.$store.getters.domain
    }
  },
  data () {
    return {
      categories: []
    }
  },
  created () {
    this.getCategories()
  },
  methods: {
    goToCategory (category) {
      this.$router.push({ path: `/app/questions/categories/${category.uuid}` })
    },
    getCategories () {
      http.get('/faq/category').then(res => {
        this.categories = res.data
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des catégories',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    create (category) {
      this.$buefy.modal.open({
        parent: this,
        component: CreateCategory,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: (created) => {
            this.categories.push(created)
            this.$buefy.notification.open({
              duration: 4000,
              message: 'La categorie a été créée avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>
