<template>
  <div>
    <form action="">
      <div class="modal-card" style="width: 960px">
        <header class="modal-card-head">
          <p class="modal-card-title">Catégorie</p>
        </header>
        <section class="modal-card-body">
          <b-field horizontal label="Label">
            <b-input type="text" placeholder="Label de la catégorie" required v-model="category.label"></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot" style="justify-content:flex-end">
          <button class="button" type="button" @click="$parent.close()">
            Annuler
          </button>
          <button class="button is-primary" @click.prevent="create">Créer</button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { http } from '@/services/api.service'

export default {
  name: 'app-categories-create',
  data () {
    return {
      category: {}
    }
  },
  methods: {
    create () {
      http.post('/faq/category', { ...this.category }).then(res => {
        this.$emit('created', res.data)
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3500,
          message: 'Nos serveurs rencontrent des difficultés à créer cette categorie',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      }).finally(() => {
        this.$parent.close()
      })
    }
  }
}
</script>
